import './App.css';
import WaitListComp from './StatefulComponent/WaitListComp';

function App() {
  return (
    <WaitListComp />
  );
}

export default App;
